// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

$theme-colors: (
  'orange': $orange,
  'pink': $pink,
);

// $enable-gradients: true;
// $enable-shadows: true;

// Bootstrap
@import '~bootstrap/scss/bootstrap';

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    @each $prop, $abbrev in (width: w, height: h) {
      @each $size, $length in $sizes {
        .#{$abbrev}#{$infix}-#{$size} { #{$prop}: $length !important; }
      }
    }
  }
}

// Custom
// @import "custom";

// Fonts
// @import "fonts";

// Animate.css
@import "~animate.css/animate";
